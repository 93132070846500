@import "styles/mixins/breakpoints";

.cross {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.2s;

  &:active {
    opacity: 0.8;
  }

  svg {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    background: white;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    position: absolute;
    z-index: 0;
    transition: 0.2s;

    @include media-breakpoint-up(xss) {
      width: 44px;
      height: 44px;
    }

    @include media-breakpoint-up(xs) {
      width: 48px;
      height: 48px;
    }
  }

  &:hover {
    &:after {
      transform: scale(1.08);
    }
  }

  @include media-breakpoint-up(xss) {
    width: 44px;
    height: 44px;
  }

  @include media-breakpoint-up(xs) {
    width: 48px;
    height: 48px;
  }

  :global(.fixedMenu) & {
    &:after {
      background: var(--light-blue-10);
    }
  }
}

.openedMenu {
  z-index: 3;

  &:after {
    background: var(--light-blue-10);
  }
}
