@import "styles/mixins/breakpoints";

.container {
  position: fixed;
  z-index: 23;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fade_in_form_content;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include media-breakpoint-down(s) {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: white;
    padding: 100px 20px;
    display: block;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--text-black-10);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--text-black-30);
    }
  }
}

@keyframes fade_in_form_content {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 22;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(112px);

  animation: fade_in_form_content;
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.cross {
  position: fixed;
  top: 33px;
  right: 29px;
  z-index: 25;

  @include media-breakpoint-down(m) {
    &:after {
      background: var(--light-blue-10);
    }
  }
}

.wrapper {
  position: relative;
  display: grid;
  border-radius: 30px;
  width: 1334px;
  max-width: 1334px;
  padding: 60px 68px;
  background-color: var(--text-white);
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 8px;
  z-index: 3;

  @include media-breakpoint-only(2xl) {
    width: 1120px;
    padding: 56px 60px;
  }

  @include media-breakpoint-down(2xl) {
    grid-template-columns: repeat(8, 1fr);
  }

  @include media-breakpoint-down(xl) {
    width: 1008px;
    padding: 48px 60px;
  }

  @include media-breakpoint-down(l) {
    width: 952px;
    padding: 40px 48px;
  }

  @include media-breakpoint-down(m) {
    width: 772px;
    padding: 40px 40px;
  }

  @include media-breakpoint-down(s) {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
}

.description {
  grid-column: auto / span 4;

  @include media-breakpoint-down(2xl) {
    grid-column: auto / span 3;
  }

  @include media-breakpoint-down(m) {
    grid-row: 1;
    grid-column: auto / span 8;
  }

  @include media-breakpoint-only(s) {
    margin-bottom: 32px;
  }

  @include media-breakpoint-only(xs) {
    margin-bottom: 28px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;
  }
}

.h4 {
  color: var(--dark-blue-90);

  @include responsive-margin-bottom(
    $xxxl: 32px,
    $xxl: 28px,
    $xl: 24px,
    $l: 16px,
    $m: 16px,
    $s: 12px,
    $xs: 16px,
    $xss: 12px
  );

  &Success {
    color: var(--dark-blue-90);

    @include responsive-margin-top(
      $xxxl: 24px,
      $xxl: 6px,
      $xl: 0px,
      $l: 0px,
      $m: 0px,
      $s: 0px,
      $xs: 0px,
      $xss: 0px
    );

    @include media-breakpoint-down(s) {
      margin: 0 auto;
    }
  }
}

.body4 {
  display: block;
  color: var(--text-black-80);

  &Success {
    text-align: center;
    max-width: 528px;
    color: var(--text-black-80);

    @include responsive-margin-top(
      $xxxl: 16px,
      $xxl: 16px,
      $xl: 12px,
      $l: 12px,
      $m: 12px,
      $s: 12px,
      $xs: 8px,
      $xss: 8px
    );

    @include media-breakpoint-down(2xl) {
      max-width: 325px;
    }

    @include media-breakpoint-down(xl) {
      max-width: 324px;
    }

    @include media-breakpoint-only(m) {
      max-width: 255px;
    }

    @include media-breakpoint-only(s) {
      max-width: 440px;
    }

    @include media-breakpoint-only(xs) {
      max-width: 335px;
    }

    @include media-breakpoint-down(xs) {
      max-width: 288px;
    }
  }
}

.inputFields {
  grid-column: auto / span 4;
  grid-column-start: 6;

  @include media-breakpoint-down(2xl) {
    grid-column-start: 5;
  }

  @include media-breakpoint-down(m) {
    grid-row: 2;
    grid-column: auto / span 8;
    grid-column-start: 1;
  }
}

.flexBlock {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;

  @include media-breakpoint-down(xl) {
    margin-bottom: 12px;
  }

  @include media-breakpoint-down(l) {
    flex-direction: column;
    gap: 12px;
  }

  @include media-breakpoint-down(xs) {
    gap: 8px;
  }
}

.input {
  margin-bottom: 20px;

  @include media-breakpoint-down(xl) {
    margin-bottom: 16px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 12px;
  }
}

.button {
  @include responsive-margin-top(
    $xxxl: 32px,
    $xxl: 28px,
    $xl: 28px,
    $l: 24px,
    $m: 24px,
    $s: 32px,
    $xs: 28px,
    $xss: 20px
  );

  &Success {
    @include responsive-margin-top(
      $xxxl: 40px,
      $xxl: 40px,
      $xl: 28px,
      $l: 28px,
      $m: 28px,
      $s: 28px,
      $xs: 24px,
      $xss: 24px
    );
  }
}

.phone {
  width: 100%;
}

.textarea {
  @include media-breakpoint-down(l) {
    margin-top: 16px;
  }

  @include media-breakpoint-down(xs) {
    margin-top: 12px;
  }
}

.successICon {
  @include responsive-margin-bottom(
    $xxxl: 20px,
    $xxl: 20px,
    $xl: 16px,
    $l: 16px,
    $m: 16px,
    $s: 16px,
    $xs: 12px,
    $xss: 12px
  );

  svg {
    width: 120px;
    height: 120px;

    @include media-breakpoint-down(3xl) {
      width: 100px;
      height: 100px;
    }

    @include media-breakpoint-down(xl) {
      width: 88px;
      height: 88px;
    }

    @include media-breakpoint-down(xs) {
      width: 80px;
      height: 80px;
    }
  }
}

.wrapperSuccess {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 160px;
  padding-bottom: 160px;

  @include media-breakpoint-down(s) {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  @include media-breakpoint-down(xs) {
    padding-top: initial;
    padding-bottom: initial;
  }
}

.caption {
  display: block;
  color: var(--text-black-100);
  white-space: normal;

  @include responsive-margin-top(
    $xxxl: 20px,
    $xxl: 20px,
    $xl: 20px,
    $l: 16px,
    $m: 16px,
    $s: 16px,
    $xs: 16px,
    $xss: 12px
  );
}

.link {
  color: var(--light-blue-100);
  transition: 0.2s;
  font-size: inherit;
  white-space: nowrap;

  &:hover {
    color: var(--light-blue-80);
  }

  &:active {
    opacity: 0.8;
  }
}

.agreementLink {
  @include media-breakpoint-down(xs) {
    white-space: wrap;
    hyphens: none;
  }
}

