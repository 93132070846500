@import "styles/variables";
@import "styles/mixins/breakpoints";

.root {
  width: 100%;

  :global {
    & .select {
      &__float-label {
        color: var(--text-black-80);
        left: 12px;
        top: 11px;

        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;

        @include media-breakpoint-down(3xl) {
          top: 12px;
        }

        @include media-breakpoint-only(l) {
          left: 8px;
          top: 9px;
        }
        @include media-breakpoint-only(m) {
          left: 8px;
          top: 9px;
        }

        @include media-breakpoint-down(m) {
          left: 8px;
          top: 9px;
        }

        @include media-breakpoint-down(xs) {
          left: 4px;
          top: 6px;
        }
      }

      &__input {
        position: relative;
        left: 1px;

        font-size: 14px;
      }

      &__input-container {
        padding-left: 10px;

        @include media-breakpoint-down(xl) {
          padding-left: 5px;
        }

        @include media-breakpoint-down(xs) {
          padding-left: 1px;
        }
      }

      &__single-value {
        padding-left: 12px;
        color: var(--text-black-100);

        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.01em;

        @include media-breakpoint-down(3xl) {
          font-size: 16px;
          line-height: 24px;
        }

        @include media-breakpoint-down(xl) {
          font-size: 15px;
          line-height: 22px;
        }

        @include media-breakpoint-only(l) {
          padding-left: 8px;
        }

        @include media-breakpoint-down(l) {
          padding-left: 8px;
        }

        @include media-breakpoint-only(xs) {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.02em;
        }

        @include media-breakpoint-down(xs) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.02em;
          padding-left: 4px;
        }
      }

      &__indicators {
        transition: 0.2s;
        transform-origin: center;
        margin-right: 24px;
        padding: 0;
      }

      &__control {
        width: 100%;
        height: 72px;
        cursor: pointer;
        letter-spacing: 0.25px;
        border: 1px solid var(--text-black-30);
        border-radius: 20px;
        transition: 0.2s;
        box-shadow: none;

        @include media-breakpoint-down(xl) {
          height: 64px;
          border-radius: 16px;
        }

        @include media-breakpoint-down(xs) {
          height: 56px;
          border-radius: 12px;
        }

        &:hover,
        &--is-focused {
          border: 1px solid var(--text-black-100);

          svg {
            transition: 0.2s;
            fill: var(--text-black-100);
          }
        }
      }

      &__control--menu-is-open {
        border: 1px solid var(--text-black-100);

        .select__indicators {
          transform: rotate(180deg);
          svg {
            fill: var(--text-black-100);
          }
        }
      }

      &__menu {
        z-index: 2;
        background-color: var(--text-white);
        border-radius: 20px;
        border: 1px solid var(--text-black-100);
        margin-top: 4px;
        box-shadow: none;

        @include media-breakpoint-down(xl) {
          border-radius: 16px;
        }
      }

      &__value-container {
        padding: 2px 12px 2px 15px;
        border-radius: 22px;
      }

      &__option {
        display: flex;
        align-items: center;
        height: 56px;
        cursor: pointer;
        padding-left: 22px;
        width: calc(100% - 8px);
        margin: 0 auto;
        border-radius: 16px;

        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.01em;

        color: var(--text-black-100);
        transition: 0.2s;

        @include media-breakpoint-down(3xl) {
          height: 48px;
          font-size: 16px;
          line-height: 24px;
        }

        @include media-breakpoint-down(xl) {
          height: 44px;
          font-size: 15px;
          line-height: 22px;
          padding-left: 18px;
        }

        &:active {
          opacity: 0.8;
        }

        &:hover {
          background-color: var(--light-blue-10);
        }

        &--is-focused {
          background-color: var(--light-blue-10);
        }

        &--is-selected {
          color: var(--light-blue-100);
          background-color: var(--text-white);

          &:hover {
            background-color: var(--text-white);
          }
        }
      }
    }
  }
}
