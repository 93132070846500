@import "styles/variables";
@import "styles/mixins/breakpoints";

.floatLabel {
  position: absolute;
  font-size: 18px;
  padding-left: 28px;
  top: 21px;

  line-height: 28px;
  letter-spacing: -0.01em;
  transition: 0.2s;

  color: var(--text-black-80);
  pointer-events: none;

  @include media-breakpoint-down(3xl) {
    padding-left: 27px;
    top: 25px;
    font-size: 16px;
    line-height: 24px;
  }

  @include media-breakpoint-down(xl) {
    top: 21px;
    padding-left: 24px;
    font-size: 15px;
    line-height: 22px;
  }

  @include media-breakpoint-only(xs) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  @include media-breakpoint-down(xs) {
    top: 18px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
  }
}

.activeLabel {
  top: 13px;

  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.01em;

  @include media-breakpoint-down(3xl) {
    top: 16px;
  }

  @include media-breakpoint-down(xl) {
    top: 13px;
  }

  @include media-breakpoint-down(xs) {
    top: 10px;
  }
}

.hasValue {
  @extend .activeLabel;
}

.input {
  width: 100%;
  height: 72px;
  border-radius: 20px;
  color: var(--text-black-100);
  border: 1px solid var(--text-black-30);

  padding: 22px 28px 6px;

  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.01em;

  background: transparent;

  outline: none;

  transition: 0.2s;
  appearance: none;

  //&::-webkit-outer-spin-button {
  //  appearance: none;
  //  -webkit-appearance: textfield;
  //  -moz-appearance: textfield;
  //}

  @include media-breakpoint-down(3xl) {
    padding: 24px 28px 6px;
    font-size: 16px;
    line-height: 24px;
  }

  @include media-breakpoint-down(xl) {
    padding: 21px 24px 6px;
    font-size: 15px;
    line-height: 22px;
    height: 64px;
    border-radius: 16px;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px 24px 3px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
  }

  @include media-breakpoint-down(xs) {
    padding: 20px 19px 6px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    border-radius: 12px;
    height: 56px;
  }

  /* TODO: без этого костыля не отрабатывает активность лейбла в инпуте,
   когда хром автозаполнил поля и данных в стейте нет, а в инпуте есть,
   это единственное свойство которое сообщает браузер
   о автозаполнении полей из соображений безопасности кражи данных!
   Штука с модалками эта не нужна, так как там есть фокус,
   а на странице пока не тапнешь по станице, лейбл не понимает что ему нужно подпрыгнуть!
   */

  &:-webkit-autofill + .floatLabel {
    top: 13px;

    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.01em;

    @include media-breakpoint-down(3xl) {
      top: 16px;
    }

    @include media-breakpoint-down(xl) {
      top: 13px;
    }

    @include media-breakpoint-down(xs) {
      top: 10px;
    }
  }

  &::placeholder {
    color: var(--text-black-60);
  }

  &:hover {
    border: 1px solid var(--text-black-100);
    cursor: pointer;
    transition: 0.2s;
  }

  &:focus {
    border: 1px solid var(--text-black-100);
    transition: 0.2s;
  }

  &:disabled {
    border: 1px solid var(--text-black-30);
    cursor: not-allowed;

    &:hover {
      border: 1px solid var(--text-black-30);
      transition: 0.2s;
    }
  }

  &:focus + .floatLabel {
    @extend .activeLabel;
  }
}

.withIcon {
  padding-right: 24px;
}

.label {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}

.labelWithError {
  margin-bottom: 0;

  & .floatLabel {
    opacity: 1;
  }
}

.helpText {
  width: 200px;
  padding-left: 25px;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
}

.lengthCounter {
  grid-column-start: 3;
  margin-top: 4px;

  color: var(--palettes-gray-80);
  font-size: 12px;
  line-height: 14px;
}

.withError {
  color: var(--text-error);
}

.withErrorBorder {
  border: 1px solid var(--text-error);

  &:hover {
    border: 1px solid var(--text-error);
    transition: 0.2s;
  }
}

.textAriaSize {
  display: block;
  resize: none;
  border-radius: 20px;

  min-width: 248px;
  height: 170px;
  padding: 29px 26px 6px;

  @include media-breakpoint-only(2xl) {
    height: 152px;
    padding: 32px 26px 6px;
  }

  @include media-breakpoint-down(2xl) {
    padding: 32px 26px 6px;
    height: 152px;
  }

  @include media-breakpoint-down(xl) {
    padding: 29px 23px 6px;
    height: 140px;
    border-radius: 16px;
  }

  @include media-breakpoint-only(xs) {
    height: 148px;
    border-radius: 20px;
  }

  @include media-breakpoint-down(xs) {
    height: 126px;
    padding: 25px 18px 6px;
    border-radius: 20px;
  }
}

.wrapperInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 6px 0 4px 0;
}

.passwordEye {
  position: absolute;

  right: 4px;
  bottom: 13px;

  display: flex;

  align-items: center;

  height: 34px;
  padding-right: 4px;
  padding-left: 4px;

  cursor: pointer;

  svg {
    display: block;
  }
}
