.loader {
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  fill: none;
  opacity: 0.5;
}

.track {
  fill: var(--text-white);
}

.progress {
  fill: var(--light-blue-100);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
