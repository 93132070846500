@import "styles/variables";
@import "styles/mixins/breakpoints";

.button {
  --button-font-size: 18px;
  --button-line-height: 135%;
  --button-background-disabled: var(--palettes-gray-20);
  --button-background: var(--text-white);
  --button-padding: 20px 28px;
  --button-height: 64px;
  --button-cursor: pointer;
  --button-border: none;
  --button-outline: none;
  --button-min-width: 180px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  user-select: none;

  min-width: var(--button-min-width);
  height: var(--button-height);
  padding: var(--button-padding);
  border: var(--button-border);
  border-radius: 52px;
  color: var(--light-purple-100);
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  letter-spacing: -0.01em;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  outline: var(--button-outline);
  cursor: var(--button-cursor);
  transition: 0.2s;
  position: relative;
  background: transparent;

  &:after {
    content: "";
    display: block;
    background-color: var(--button-background);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: 0.2s;
    border-radius: 52px;
  }

  &:hover {
    &:after {
      transform: scale(1.05);
    }
  }

  &:active {
    --button-background: var(--light-purple-10);
  }

  span {
    position: relative;
    z-index: 1;
    font-weight: 500;
  }

  @include media-breakpoint-down(3xl) {
    --button-min-width: 158px;
    --button-height: 56px;
    --button-padding: 18px 24px;

    --button-font-size: 16px;
    --button-line-height: 125%;
  }

  @include media-breakpoint-down(xl) {
    --button-min-width: 137px;
    --button-height: 48px;
    --button-padding: 15px 20px;

    --button-font-size: 14px;
    --button-line-height: 125%;
  }

  @include media-breakpoint-only(xs) {
    --button-min-width: 143px;
    --button-height: 48px;
    --button-padding: 16px 20px;

    --button-font-size: 15px;
    --button-line-height: 105%;
  }

  @include media-breakpoint-down(xs) {
    --button-min-width: 133px;
    --button-height: 44px;
    --button-padding: 15px 18px;

    --button-font-size: 14px;
    --button-line-height: 100%;
  }

  &:disabled {
    box-shadow: none;
    color: #cecece;
    filter: none;

    --button-cursor: not-allowed;
    --button-background: var(--text-black-20);

    &:after {
      transform: scale(1);
    }

    &:active,
    &:hover {
      --button-background: var(--text-black-20);
    }
  }
}

.purple {
  color: var(--light-purple-100);
  --button-background: var(--text-white);

  &:active {
    --button-background: var(--light-purple-10);
  }
}

.purpleDark {
  color: white;
  --button-background: var(--light-purple-100);

  &:active {
    --button-background: var(--light-purple-80);
  }
}

.marine {
  color: var(--light-marine-100);
  --button-background: var(--text-white);

  &:active {
    --button-background: var(--light-marine-10);
  }
}

.marineDark {
  color: white;
  --button-background: var(--light-marine-100);

  &:active {
    --button-background: var(--light-marine-80);
  }
}

.lilac {
  color: var(--light-lilac-100);
  --button-background: var(--text-white);

  &:active {
    --button-background: var(--light-lilac-10);
  }
}

.lilacDark {
  color: white;
  --button-background: var(--light-lilac-100);

  &:active {
    --button-background: var(--light-lilac-80);
  }
}

.blue {
  color: var(--light-blue-100);
  --button-background: var(--text-white);

  &:active {
    --button-background: var(--light-blue-10);
  }
}

.blueDark {
  color: white;
  --button-background: var(--light-blue-100);

  &:active {
    --button-background: var(--light-blue-80);
  }
}

.peach {
  color: var(--light-peach-100);
  --button-background: var(--text-white);

  &:active {
    --button-background: var(--light-peach-10);
  }
}

.peachDark {
  color: white;
  --button-background: var(--light-peach-100);

  &:active {
    --button-background: var(--light-peach-80);
  }
}

.secondary {
  --button-color: var(--dark_grey);
  --button-color-hover: var(--dark_grey);
  --button-background: var(--fon_1);
  --button-background-hover: var(--fon_1);

  filter: drop-shadow(0px 0px 0px rgba(184, 184, 184, 0.4));

  &:hover {
    filter: drop-shadow(0px 0px 20px rgba(184, 184, 184, 0.4));
  }

  &:active {
    filter: drop-shadow(0px 0px 0px rgba(184, 184, 184, 0.4));
    background: rgba(255, 255, 255, 0.7);
  }

  &:disabled {
    --button-cursor: not-allowed;
    color: var(--grey_6);
    filter: drop-shadow(0px 0px 0px rgba(184, 184, 184, 0.4));
    background: var(--fon_1);
  }
}

.tertiary {
  --button-border: 1px solid rgba(255, 255, 255, 0.2);
  --button-color: #fff;
  --button-background: none;

  --button-background-disabled: none;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &:active {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.2;
  }
}

.large {
  --button-font-size: 16px;
  letter-spacing: -1px;

  --button-height: 48px;
  --button-padding: 20px 24px;
}

.medium {
  --button-font-size: 16px;

  --button-height: 36px;
  --button-padding: 8px 16px 12px 16px;
}

.grow {
  width: 100%;
}

.examplePage {
  background: #11012c;
  color: white;
}

.linkExample {
  color: white;
}

.small {
  --button-min-width: 100px;

  @include media-breakpoint-down(xl) {
    --button-min-width: 86px;
  }
}
