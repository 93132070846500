.helpText {
  margin-top: 4px;
  margin-left: 16px;

  color: var(--gray-palettes-90);
  font-size: 12px;
  letter-spacing: 0.4px;
}

.withError {
  color: var(--select-error-text-color);
}

.label {
  width: 100%;
  position: relative;

  display: block;

  input {
    position: relative;
    top: 6px;
    left: 1px;
  }
}

.floatLabel {
  position: absolute;
  top: 24px;
  z-index: 1;

  width: 94.5%;
  height: 20px;
  margin: 0 4px;
  padding: 0 0 0 12px;

  color: var(--select-float-label-color);
  font-size: var(--select-float-label-font-size);
  line-height: var(--select-float-label-line-height);
  letter-spacing: var(--select-float-label-letter-spacing);

  transition: 0.2s;
  pointer-events: none;
}

.activeLabel {
  top: 2px;

  padding-top: 4px;

  font-size: 12px;
  line-height: 14px;
}

